import { hideLoginPopup } from "actions";
import { APP_SLUGS } from "constants/constants";
import React, { useContext } from "react";
import { useAppDispatch, useAppSelector } from "redux/Store";
import { SdkContext } from "providers/client-sdk-providers";
import TRENDI_LOGO from "assets/clients/trendi/Trendi_Logo.png";
import CHOPE_STORMS_LOGO from "assets/clients/chope-storms/logo.png";
import XCTUALITY_LOGO from "assets/clients/xctuality/xctuality_logo.png";
import PRESENTE_LOGO from "assets/images/presente/logo.png";
import SKIBIDI_LOGO from "assets/images/skibidi/skibidi_logo.png";
import { css, useTheme } from "@emotion/react";
import { Label } from "reactstrap";
import { isStringEqual } from "utils";
import { isFegg, isGo3, isGoamaPH, isMetaVerseGo } from "utils/applicationSlug";
import SabayLoginModal from "assets/clients/sabay/SabayModals";
import FoodPandaDriverIdLoginModal from "components/feature-specific/Modals/FoodPandaModals/FoodPandaDriverIdLoginModal";
import LoginPopUpModal from "components/feature-specific/auth/desktop/LoginPopUPModal";
import EmailOnlyLogin from "./EmailOnlyLogin";
// const SabayLoginModal = lazy(() => import("../../../components/clients/sabay/SabayModals"));
// const EmailOnlyLogin = lazy(() => import("./EmailOnlyLogin"));
// const LoginPopUpModal = lazy(() => import("components/feature-specific/auth/desktop/LoginPopUPModal"));
// const FoodPandaDriverIdLoginModal = lazy(
//   () => import("../../../components/feature-specific/Modals/FoodPandaModals/FoodPandaDriverIdLoginModal"),
// );

const LoginPopUpHandler = () => {
  const theme: ITheme = useTheme();
  const dispatch = useAppDispatch();
  const { loginPopupVisibility, platformLoaded } = useAppSelector((state: any) => state.layout);
  const { application, user } = useAppSelector(state => state.common);
  const { modalInstance } = useAppSelector(state => state.cryptoToken);
  const sdk = useContext(SdkContext);
  const { isRazerGuestUser = null } = sdk;
  const isGuestUser = application?.applicationsetting?.enable_guest_user && user?.extra_data?.guest;

  if (isRazerGuestUser && isRazerGuestUser() && loginPopupVisibility) {
    window.Cortex && window.Cortex.showLoginReminderDialog();
    setTimeout(() => {
      dispatch(hideLoginPopup()); // ANCHOR hide login pop up
    }, 50);
    return null;
  } else if (isGo3 && loginPopupVisibility) {
    if (modalInstance) {
      modalInstance.open();
    }
    setTimeout(() => {
      dispatch(hideLoginPopup()); // ANCHOR hide login pop up
    }, 50);
    return null;
  } else if (user) {
    if (isGuestUser) {
      return <LoginPopUpModal show={loginPopupVisibility} onHide={() => dispatch(hideLoginPopup())} />;
    }
    return null;
  } else if (isMetaVerseGo || isGoamaPH || isFegg) return null;
  else if (isStringEqual(application?.slug, APP_SLUGS.SABAY)) {
    return <SabayLoginModal show={loginPopupVisibility} onHide={() => dispatch(hideLoginPopup())} />;
  } else if (isStringEqual(application?.slug, APP_SLUGS.FOODPANDA)) {
    return <FoodPandaDriverIdLoginModal show={loginPopupVisibility} onHide={() => dispatch(hideLoginPopup())} />;
  } else if (isStringEqual(application?.slug, APP_SLUGS.TRENDI)) {
    return (
      <EmailOnlyLogin
        header="Play to Win!"
        body="Welcome to Trendi’s mobile game tournament! Play to WIN prizes and share with your friends to continue spreading the word about food waste!"
        logo={TRENDI_LOGO}
        appName="Trendi"
        textColor={"#bfe203"}
        bgColor={"#004b23"}
        show={loginPopupVisibility}
        onHide={() => dispatch(hideLoginPopup())}
        defaultTncLink={false}
        tncLink="https://goama.com/tnc-trendi/"
      />
    );
  } else if (isStringEqual(application?.slug, APP_SLUGS.PRESENTE)) {
    return (
      <EmailOnlyLogin
        // header="Play to Win!"
        body="¡Bienvenido, haz dado el primer paso para lograr tus sueños. Ahora te invitamos a jugar y a ganar!"
        logo={PRESENTE_LOGO}
        appName="Presente"
        textColor={"#B8DDF2"}
        bgColor={"#144571"}
        show={loginPopupVisibility}
        onHide={() => dispatch(hideLoginPopup())}
        style={buttonPresente}
        footerText="Recuerda que el código de acceso te llegó al correo electrónico y/o número de celular que tienes registrado en PRESENTE."
      />
    );
  } else if (isStringEqual(application?.slug, APP_SLUGS.CHOPE_STORMS)) {
    return (
      <EmailOnlyLogin
        customClass={application?.slug}
        header=""
        appName="Chope"
        body="Welcome to Chope instant game tournament! Play to WIN exciting prizes! Register now!"
        logo={CHOPE_STORMS_LOGO}
        textColor={theme.text.primary}
        bgColor={theme.foreground.primary}
        show={loginPopupVisibility}
        onHide={() => dispatch(hideLoginPopup())}
        tncLink="https://www.chope.co/singapore-restaurants/tc"
        defaultTncLink={false}
        hidePrivacyPolicy
        customCheckboxElement={
          <Label className="tnc-text">
            I consent to the disclosure of my data to Chope and agreed to the{" "}
            <a href={"https://www.chope.co/singapore-restaurants/tc"} target="_blank" rel="noopener noreferrer">
              T&C{" "}
            </a>
            to collect and use it for Chope&apos;s communications.
          </Label>
        }
      />
    );
  } else if (isStringEqual(application?.slug, APP_SLUGS.XCTUALITY)) {
    return (
      <EmailOnlyLogin
        customClass={application?.slug}
        header="Play & Win!"
        appName="Xctuality"
        body="Welcome to Xctuality’s mobile game tournament. Play to win tickets and claim exciting prizes!"
        logo={XCTUALITY_LOGO}
        textColor={"#FFFFFF"}
        bgColor={"#000000"}
        show={loginPopupVisibility}
        onHide={() => dispatch(hideLoginPopup())}
        tncLink="https://www.chope.co/singapore-restaurants/tc"
        defaultTncLink={true}
        style={buttonXctuality}
        customCheckboxElement={
          <Label className="tnc-text">
            I agree to the{" "}
            <a href="https://goama.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            and T&C of{" "}
            <a href="https://goama.com/terms/" target="_blank" rel="noopener noreferrer">
              Goama
            </a>{" "}
            and{" "}
            <a href=" https://xctualyfe.com/terms" target="_blank" rel="noopener noreferrer">
              Xctuality
            </a>{" "}
            to collect and use it for Xctuality and partner&apos;s communications.
          </Label>
        }
      />
    );
  } else if (isStringEqual(application?.slug, APP_SLUGS.SKIBIDI_TOILET)) {
    return (
      <EmailOnlyLogin
        customClass={application?.slug}
        header="Play. Win. Donate."
        appName="Skibidi-Toilot"
        body="Welcome to an awesome gaming experience! Win tickets to redeem a gift card or donate to a better cause. Join us on this journey of play and positive change"
        logo={SKIBIDI_LOGO}
        textColor={"#FFFFFF"}
        bgColor={"#000000"}
        show={loginPopupVisibility}
        onHide={() => dispatch(hideLoginPopup())}
        tncLink="https://www.chope.co/singapore-restaurants/tc"
        defaultTncLink={true}
        style={buttonXctuality}
        customCheckboxElement={
          <Label className="tnc-text">
            I agree to the &nbsp;
            <a href="https://goama.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            &nbsp; &nbsp; and T&C of &nbsp;
            <a href="https://goama.com/terms/" target="_blank" rel="noopener noreferrer">
              Goama
            </a>{" "}
            &nbsp; &nbsp; to collect and use it for and partner&apos;s communications.
          </Label>
        }
      />
    );
  } else {
    return platformLoaded && <LoginPopUpModal show={loginPopupVisibility} onHide={() => dispatch(hideLoginPopup())} />;
  }
};

export default LoginPopUpHandler;

const buttonXctuality = css`
  background-color: #79056d !important;
  color: #ffffff !important;

  &:hover {
    background-color: "#79056D" !important;
  }
`;

const buttonPresente = css`
  background-color: #f8c21e !important;
  color: #144571 !important;
  margin-bottom: 30px !important;
  margin-top: 10px !important;
`;
